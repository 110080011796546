import Card from '@comparacar/components/src/generic/CardMobile'
import MainSearch from '@comparacar/components/src/generic/MainSearch'
import TalkSpecialistButton from '@comparacar/components/src/generic/TaskSpecialistButton'
import Administradores from '@comparacar/components/src/icons/brands/Administradores'
import QuatroRodas from '@comparacar/components/src/icons/brands/QuatroRodas'
import Startups from '@comparacar/components/src/icons/brands/Startups'
import Terra from '@comparacar/components/src/icons/brands/Terra'
import Valor from '@comparacar/components/src/icons/brands/Valor'
import ResponsiveYoutubeEmbed from '@comparacar/components/src/main/ResponsiveYoutubeEmbed'
import LinksSection from '@comparacar/components/src/molecules/LinksSection'
import commonTitles from '@comparacar/lib/src/common-titles'
import { normalizeSubscription } from '@comparacar/lib/src/utils/normalizeToResultCard'
import { useTheme } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import React from 'react'

const Main: React.FC<{ searchOption?: engine; videoList: Video[]; links: Link[]; highlights: Subscription[] }> = ({
  searchOption = 'buy',
  videoList,
  highlights,
  links
}) => {
  const theme = useTheme()

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          right: { xs: 16, sm: 24 },
          left: { xs: 16, sm: 'unset' },
          bottom: 24,
          fontSize: 48,
          zIndex: theme.zIndex.fab
        }}
      >
        <TalkSpecialistButton />
      </Box>
      <Stack gap={10} mt={10}>
        <Box my={3}>
          <Box maxWidth={778} margin="auto" mb={14}>
            <Typography fontWeight="600" component="h1" align="center" variant="h3" mb={{ md: 0.5, xs: 2 }}>
              {commonTitles.mainTitle}
            </Typography>
            <Typography fontWeight="600" color="textSecondary" align="center" variant="h4">
              {commonTitles.mainSubtitle}
            </Typography>
          </Box>
          <MainSearch engine={searchOption} />
        </Box>
        <div>
          <Typography variant="h4" mb={3}>
            Somos destaque em:
          </Typography>
          <Stack spacing={2} direction="row" flexWrap="wrap" useFlexGap justifyContent="space-between">
            <QuatroRodas width="15%" />
            <Valor width="15%" />
            <Startups width="15%" />
            <Administradores width="15%" />
            <Terra width="15%" />
          </Stack>
        </div>
        {Boolean(highlights.length) && (
          <div>
            <Typography variant="h3" mb={7}>
              Ofertas em destaque
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                scrollbarWidth: 'thin',
                overflowY: 'hidden',
                overflowX: 'auto',
                '& > *': {
                  flexShrink: 0,
                  ml: 2
                }
              }}
            >
              {highlights.map(item => (
                <Card data={normalizeSubscription(item)} key={item.id} hideOfferButton />
              ))}
            </Box>
          </div>
        )}
        <div>
          <Typography variant="h3" mb={7}>
            Conteúdo destaque do mês
          </Typography>
          <ResponsiveYoutubeEmbed url={videoList[0].url} />
        </div>
        <LinksSection urlPrefix="/assinar/carro/" links={links} />
      </Stack>
    </>
  )
}

export default Main
